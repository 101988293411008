import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import Swal from 'sweetalert2';
import './style.css';

const Home = () => {
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const school = localStorage.getItem('school');
  const folder = 'StudentWebsites/' + school + '/' + firstName.trim() + lastName.trim();

  const [file, setFile] = useState(null);
  const [userFiles, setUserFiles] = useState([]);
  const [updateFileList, setUpdateFileList] = useState('');
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);

  const S3_BUCKET = 'steamworksstudio';
  const REGION = 'us-east-2';

  useEffect(() => {
    getUserFiles();
  }, [updateFileList]);

  const getUserFiles = async () => {
    console.log("User Folder: " + folder);

    AWS.config.update({
      accessKeyId: 'AKIA4LEJ6JWATDMJSAWL',
      secretAccessKey: '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',
      //accessKeyId: process.env.ACCESS_KEY,
      //secretAccessKey: process.env.SECRET_KEY,
    });
    
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Delimiter: '',
      Prefix: folder
    };

    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        console.log(data.Contents);
        setUserFiles(data.Contents);
      }
    });
  }

  const uploadFile = async () => {
    AWS.config.update({
      accessKeyId: 'AKIA4LEJ6JWATDMJSAWL',
      secretAccessKey: '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',
      //accessKeyId: process.env.ACCESS_KEY,
      //secretAccessKey: process.env.SECRET_KEY,
    });
    
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: folder + '/' + file.name,
      Body: file,
    };

    try {
      const upload = await s3.putObject(params).promise();
      console.log(upload);
      setUpdateFileList(upload);
      document.getElementById('file').value = null;
      setUploadButtonDisabled(true);
    } catch (error) {
      console.error("Error uploading file: " + error.message);
    }
  };

  const deleteFile = async (file) => {
    AWS.config.update({
      accessKeyId: 'AKIA4LEJ6JWATDMJSAWL',
      secretAccessKey: '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',
      //accessKeyId: process.env.ACCESS_KEY,
      //secretAccessKey: process.env.SECRET_KEY,
    });
    
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: file
    };

    Swal.fire({
      title: "Are you sure you want to delete this file?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        s3.deleteObject(params, function (error, data) {
          if (data) {
            setUpdateFileList(data);
            console.log("File deleted successfully");
          } else {
            console.log("Check if you have sufficient permissions : " + error);
          }
        });
      }
    });
  };
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setUploadButtonDisabled(false);
  };

  return(
    <div className="home-page">
      <h3>{firstName} {lastName} {' - ' + school + ' School'}</h3>
      <table className='table'>
        <thead>
          <tr>
            <th>File</th>
            <th>Size</th>
            <th>Last Modified</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {userFiles && userFiles.map((name, index) => {
          if (name.Size > 0) { 
            return (
              <tr key={index}>
                <td><a target="_blank" href={`https://steamworksstudio.s3.us-east-2.amazonaws.com/${name.Key}`}>{name.Key.slice(name.Key.lastIndexOf('/') + 1)}</a></td>
                <td>{name.Size + ' bytes'}</td>
                <td>{new Date(name.LastModified + '').toLocaleString()}</td>
                <td><button className="btn-danger" onClick={() => deleteFile(name.Key)}>Delete</button></td>
              </tr>
            )
          }
        })}
        </tbody>
      </table> 
      <br /><br />
      <div className="form">        
        <input type="file" id="file" onChange={handleFileChange} />
        <button onClick={uploadFile} id="upload-button" disabled={uploadButtonDisabled}>Upload</button>
      </div>
    </div>
  );
}

export default Home;