import { createContext } from "react";

const UserDataContext = createContext({
  firstName: '',
  lastName: ''
});

export default UserDataContext;

export const UserDataProvider = ({ children }) => {
  return (
    <UserDataContext.Provider value={{
      firstName: '',
      lastName: ''
    }}>
      { children }
    </UserDataContext.Provider>
  );
};