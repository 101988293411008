import './App.css';
import { UserDataProvider } from './context/UserData';
import AppRoutes from './routes';

function App() {
  return (
    <div className="App">
      <UserDataProvider>
        <AppRoutes />
      </UserDataProvider>
    </div>
  );
}

export default App;
